/*
Header height: 50px
Buttons: around 77px

Assuming header area: 150x with a little padding

TODO: We're changing the chart area to a fixed 1.7 ratio; everything else should feed off of it.


*/

:root {
    --interfaceHeight: calc(96vh - 90px);
    --codeEntryWidth: calc(98vw - (70vh * (5/3)) - 3px);
    --chartHeight: 65vh;
    --chartWidth: calc(70vh * (5/3));
    --gutterWidth: 3px;
  }

.terminal {
    z-index: 99;
}

.terminal textarea {
    z-index: 99;
    height: 100%;
    width: 100%;

}

.chartArea {
    border-top: solid; 
    border-right: solid;
    border-left: solid;
    z-index: 5;
   
}


.hide {
    display: none;
}


.codeEntry div, .codeEntry textarea{
    border: none;

}


.interfaceGrid {
    z-index: 500;
    position: relative;
    margin: 0 auto;
    height: var(--interfaceHeight);
    width: 98vw;
}

.chartArea {
    resize: both;
    position: absolute;
    top: 0;
    right: 0;
    width: var(--chartWidth);
    height: var(--chartHeight);
}

#gutter {
    position: absolute;
    width: var(--gutterWidth);
    height: var(--interfaceHeight);
    top: 0;
    left: calc(var(--codeEntryWidth) - var(--gutterWidth));
}

.fullPage {
    z-index: 0;
    background-color: black;
    opacity: .5;
    width: 100vw;
    height: 100vh;
    position: absolute;

}

#gutter:hover {

    cursor: col-resize;

}

.codeEntry {
    width: calc(var(--codeEntryWidth) - var(--gutterWidth));
    height: var(--interfaceHeight);
}

.terminal {
    position: absolute;
    height: calc(var(--interfaceHeight) - var(--chartHeight) - 1px);
    bottom: 0;
    right: 0;
    width: var(--chartWidth);
}

.top {
    z-index: 100;
}

#codeEntryDiv{
    border: 3px solid black;
    border-radius: 5px;
}