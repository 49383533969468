.project:hover {
    background-color: rgba(0, 0, 0, .1);
}


.top-buttons {
    height: 20px;
}

.delete-confirmation {
    background-color: #1AA38F;
    width: 100%;
    min-height: 80vh;
    height: 100%;
}

.disabled {
    color: gray;
}

.top-buttons ul {
    cursor: pointer;
    display: flex;
    padding-left: 1vw;
    justify-content: left;
    position: absolute;
    top: 50px;
    z-index: 50;
    left: 0;
    list-style-type: none;
  }


  #view-tabs {
      left: calc(var(--codeEntryWidth) + var(--gutterWidth) )
  }

  
  .top-buttons ul li {
    color: black;
    font-size: 1em;
    border: 1px solid black;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 10px;
    height: 40px;
    background-color: #B3DDE7;
    text-align: center;
    text-decoration: none;
  }

  
#go-button {
    height: 28px;
    transition: 0.5s;
}

#go-button:hover {
    background-color: #1b3752;
    color: white;
    animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1);
}

#go-button:active {
    background-color: #8badb5;
}

  .activeButton {
      background-color: #8badb5 !important;
  }

  #go-button {
      margin-right: 15px;
  }


.user-menu-data {
    top: 10px;
    background-color: #1AA38F;
    position: absolute;
    left: calc(100%);
    min-height: 100px;
    display: none;
}

.block {
    display: block;
}